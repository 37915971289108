var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "homeCard" }, [
    _c("div", { staticClass: "health" }, [
      _c("div", { staticClass: "card j" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "desc" },
          [
            _c("h2", [_vm._v("健康体检系统")]),
            _c(
              "Button",
              {
                on: {
                  click: function ($event) {
                    return _vm.handel(2, "健康体检")
                  },
                },
              },
              [_vm._v("点击进入")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "card c" }, [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "desc" },
          [
            _c("h2", [_vm._v("从业体检系统")]),
            _c(
              "Button",
              {
                on: {
                  click: function ($event) {
                    return _vm.handel(3, "从业体检")
                  },
                },
              },
              [_vm._v("点击进入")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "card c" }, [
        _vm._m(2),
        _c(
          "div",
          { staticClass: "desc" },
          [
            _c("h2", [_vm._v("财务管理")]),
            _c(
              "Button",
              {
                on: {
                  click: function ($event) {
                    return _vm.handel(7, "财务管理")
                  },
                },
              },
              [_vm._v("点击进入")]
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "profession" }, [
      _c(
        "div",
        { staticClass: "l" },
        [
          _c("h2", [_vm._v("职业体检系统")]),
          _c(
            "Button",
            {
              on: {
                click: function ($event) {
                  return _vm.handel(1, "职业体检")
                },
              },
            },
            [_vm._v("点击进入")]
          ),
        ],
        1
      ),
      _vm._m(3),
    ]),
    _c("div", { staticClass: "xray" }, [
      _c(
        "div",
        { staticClass: "t" },
        [
          _c("h2", [_vm._v("放射体检系统")]),
          _c(
            "Button",
            {
              on: {
                click: function ($event) {
                  return _vm.handel(4, "放射体检")
                },
              },
            },
            [_vm._v("点击进入")]
          ),
        ],
        1
      ),
      _vm._m(4),
    ]),
    _c("div", { staticClass: "sys" }, [
      _c("div", { staticClass: "card j" }, [
        _vm._m(5),
        _c(
          "div",
          { staticClass: "desc" },
          [
            _c("h2", [_vm._v("LIS系统")]),
            _c("Button", { on: { click: _vm.gotoLIS } }, [_vm._v("点击进入")]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "card c" }, [
        _vm._m(6),
        _c(
          "div",
          { staticClass: "desc" },
          [
            _c("h2", [_vm._v("PACS系统")]),
            _c("Button", { on: { click: _vm.gotoPACS } }, [_vm._v("点击进入")]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wrapper" }, [
      _c("img", { attrs: { src: require("@/assets/健康.png") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wrapper" }, [
      _c("img", { attrs: { src: require("@/assets/从业.png") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wrapper" }, [
      _c("img", { attrs: { src: require("@/assets/财务.png") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "r" }, [
      _c("img", { attrs: { src: require("@/assets/职业.png") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d" }, [
      _c("img", { attrs: { src: require("@/assets/放射.png") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wrapper" }, [
      _c("img", { attrs: { src: require("@/assets/LIS.png") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wrapper" }, [
      _c("img", { attrs: { src: require("@/assets/PACS.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }